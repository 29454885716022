import React, { useState, useMemo, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Button, Stack, } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import OZDataGrid from '../../../components/admin/common/OZDataGrid/OZDataGrid';
import { deleteSkillById, getagentsDropdown, getDialoutNumbers, getLoggedInAgentsGroupWise, getSkillById, getSkillDropdown, getSkillFlow, updateSkillById, } from '../../../services/api-service';
import SkillForm from './SkillForm';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import OZGridToolbar from '../../../components/admin/common/OZDataGrid/OZGridToolbar';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from '../../../components/admin/common/NotiService';
import { checkRoles } from '../../../utils/util';
import { newAuthSubAdminAccess, NewAdminPages, newAuthPages } from '../../../services/page-service';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';


function Skill(props) {
  const [showSkillsForm, setShowSkillsForm] = useState({ isNew: false, show: false });
  const [onclickRowSkillData, setOnclickRowSkillData] = useState({});
  const [skillTableData, setSkillTableData] = useState([]);
  const [gridRef, setGridRef] = useState(null)
  const [agentDataList, setAgentDataList] = useState([]);
  const [dailoutList, setDailoutList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skillList, setSkillList] = useState([]);

  useEffect(() => {
    getSkill();
    getDropDown();
    getSkillsDropDown();
    getDailoutDropDown();
  }, []);

  async function getDropDown() {
    try {
      getagentsDropdown().then(resp => {
        setAgentDataList(resp)
      }).catch(e => {
        showErrorNotification("No agent Data Available, Check your Internet")
      })
    }
    catch (e) {
      showErrorNotification(JSON.stringify(e));
      setAgentDataList([])
    }
  };

  async function getSkill() {
    setLoading(true);
    try {
      getSkillFlow().then(resp => {
        setSkillTableData(resp.Data);
        setLoading(false);
        // localStorage.clear();
      }).catch(e => {
        setSkillTableData({})
        setLoading(false);
      })
    }
    catch (e) {
      showErrorNotification(JSON.stringify(e))
      setLoading(false);
    }
  };

  async function deleteSkillsById(id) {
    try {
      deleteSkillById(id)
        .then((resp) => {

          if (resp.Status === "Success") {
            showSuccessNotification(`Skill ${id.skillName} has been deleted Successfully`);
            closeSkillForm();
            getSkill()
          } else {
            showErrorNotification(JSON.stringify(resp.Message));
          }
        })
        .catch((e) => { });
    } catch (e) {
      showWarningNotification(e);
    }
  };

  function skillsCallBack(type, id) {
    if (type === "listFunction") {
      getSkill();
    }
    if (type === "deleteFunction") {
      deleteSkillsById(id);
    }
  };

  async function getSkillsDropDown() {
    try {
      getSkillDropdown()
        .then((resp) => {
          setSkillList(resp.Data);
        })
        .catch((e) => {
          setSkillList([]);
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
      setSkillList([]);
    }
  }


  async function getDailoutDropDown() {
    try {
      getDialoutNumbers().then((resp) => {
        setDailoutList(resp.Data);
      }).catch((e) => {
        setDailoutList([{ id: "0", skillName: "No Internet" }]);
        showErrorNotification(
          "No Dailout Number Data Available, Check your Internet"
        );
      });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
      setDailoutList([]);
    }
  }


  async function updateSkills(data, mode) {
    // console.log("here while update in jsx skills", data);
    setLoading(true);
    data.recOn = data.recOn.recOn;
    if (data.queueTimeOut_Min === 0 && data.queueTimeOut_Sec === 0) {
      data.fallBackRule = 2
    }
    else {
      data.fallBackRule = data.fallBackRule.fallBackRule
    }
    if (!data.skillDetail1) {
      data.skillDetail1 = null;
    }
    if (!data.skillDetail2) {
      data.skillDetail2 = null;
    }
    if (!data.skillDetail3) {
      data.skillDetail3 = null;
    }
    if (data.fallBackRule === 2) {
      data.ivrFlow = null
      data.queueSkillTransfer = null
      data.dialOut = null
    }
    if (data.dropAction === false) {
      data.callDropActions = null
    }

    let qtime = (data.queueTimeOut_Min * 60) + data.queueTimeOut_Sec
    data.queueTimeOut = qtime
    if (data.fallBackRule === 6) {
      let queuetime = []
      let skillName = []
      data?.skills?.map((row) => {
        let queueTimeOut = row.multiskillqueueTimeOut_Min * 60 + row.multiskillqueueTimeOut_Sec;
        queuetime.push(queueTimeOut);
        skillName.push(row.skills);
        delete row.skillList;
      })
      delete data.skills;
      let multiSkillJsonArray = [];
      for (let i = 0; i < queuetime.length; i++) {
        multiSkillJsonArray.push({
          queueTime: queuetime[i],
          skillName: skillName[i].skillName,
          id: skillName[i].id
        })
      }
      let fallBackDetailsJson = {}
      fallBackDetailsJson.skills = multiSkillJsonArray;
      fallBackDetailsJson.fallbackType = data.fallbackType.fallbackType;
      let TransferSkillLists = []
      skillList?.map((row) => {
        TransferSkillLists.push(row.skillName)
      })

      // if (fallBackDetailsJson.fallbackType === 4) {
      //   fallBackDetailsJson.fallbackValue = data.queueSkillTransfer.skillName;
      // }
      // else if (fallBackDetailsJson.fallbackType === 5) {
      //   if (data.queueIvrTransfer === "") {
      //     data.queueIvrTransfer = null;
      //     fallBackDetailsJson.fallbackValue = data.queueIvrTransfer;
      //   }
      //   else {
      //     fallBackDetailsJson.fallbackValue = data.queueIvrTransfer;
      //   }
      // }
      // else if (fallBackDetailsJson.fallbackType === 1) {
      //   fallBackDetailsJson.fallbackValue = data.dialOut.dialOutName;
      // }
      // else {
      //   delete fallBackDetailsJson.fallBackRule;
      // }

      //only for testing when falalbackrule is 6 so converting//
      if (fallBackDetailsJson.fallbackType === 4) {
        fallBackDetailsJson.fallbackValue = data?.queueSkillTransferMulti?.skillName;
      }
      else if (fallBackDetailsJson.fallbackType === 5) {
        if (data.queueIvrTransferMulti === "") {
          data.queueIvrTransferMulti = null;
          fallBackDetailsJson.fallbackValue = data?.queueIvrTransferMulti;
        }
        else {
          fallBackDetailsJson.fallbackValue = data?.queueIvrTransferMulti;
        }
      }
      else if (fallBackDetailsJson.fallbackType === 1) {
        fallBackDetailsJson.fallbackValue = data?.dialOutMulti?.dialOutName;
      }
      else {
        delete fallBackDetailsJson.fallBackRule;
      }
      //only for testing when falalbackrule is 6 so converting//

      fallBackDetailsJson.skillIndex = 0;
      delete data.fallbackType;
      data.fallbackDetails = JSON.stringify(fallBackDetailsJson)
    }
    else {
      // delete data.fallbackType;
      // delete data.skills;
      // data.fallbackDetails = null;
    }
    if (data.ivrFlow?.id === "1_Other" && data.ivrFlow.flowName === "Other") {
      data.ivrFlow = null;
    }
    delete data.queueIvrTransferMulti;
    delete data.queueSkillTransferMulti;
    delete data.dialOutMulti;
    try {
      updateSkillById(data)
        .then((resp) => {
          if (resp.Status === "Success") {
            getSkillById(data.id).then(resp => {

              if(localStorage.getItem('isAdmin') === false || localStorage.getItem('isAdmin') === 'false'){
                //::::::::for SubAdmin:::::::://
                let temp = structuredClone(resp?.Data[0])
                if (temp?.fallbackDetails === null) {
                  let fallbackType = { fallbackType: 2, Type: "Disconnect" };
                  const newData = {
                    ...temp,
                    fallbackType,
                  };
                  temp = newData;
                }
                if (temp?.fallbackDetails) {
                  // Parse the fallbackDetails string to JSON
                  const fallbackDetails = JSON.parse(temp.fallbackDetails);
  
                  // Create the skills array
                  const skills = fallbackDetails?.skills?.map(skill => {
                    let queueTimeSec = skill.queueTime;
                    let queueTimeMin = Math.floor(queueTimeSec / 60);
                    queueTimeSec %= 60;
                    return {
                      skills:
                      {
                        skillName: skill?.skillName,
                        id: skill?.id
                      },
                      multiskillqueueTimeOut_Min: queueTimeMin,
                      multiskillqueueTimeOut_Sec: queueTimeSec
                    };
                  });
  
                  let fallbackType = {};
                  let queueIvrTransferMulti = "";
                  let queueSkillTransferMulti = null;
                  let dialOutMulti = null;
  
  
                  if (fallbackDetails?.fallbackType === 1 || fallbackDetails?.fallbackType === "1") {
                    fallbackType = { fallbackType: 1, Type: "Dialout" };
                    dialOutMulti = dailoutList.find(item => item.dialOutName === fallbackDetails?.fallbackValue);
                  } else if (fallbackDetails?.fallbackType === 2 || fallbackDetails?.fallbackType === "2") {
                    fallbackType = { fallbackType: 2, Type: "Disconnect" };
                  } else if (fallbackDetails?.fallbackType === 3 || fallbackDetails?.fallbackType === "3") {
                    fallbackType = { fallbackType: 3, Type: "voicemail" };
                  } else if (fallbackDetails?.fallbackType === 4 || fallbackDetails?.fallbackType === "4") {
                    fallbackType = { fallbackType: 4, Type: "Skill" };
                    queueSkillTransferMulti = skillList.find(item => item.skillName === fallbackDetails?.fallbackValue);
                  } else if (fallbackDetails?.fallbackType === 5 || fallbackDetails?.fallbackType === "5") {
                    fallbackType = { fallbackType: 5, Type: "IVR" };
                    queueIvrTransferMulti = fallbackDetails.fallbackValue
                  } 
                  else if (fallbackDetails?.fallbackType === 7 || fallbackDetails?.fallbackType === "7") {
                    if(checkRoles('ROLE_PARKING')){
                      fallbackType = { fallbackType: 7, Type: "Parking" };
                    }else{
                      fallbackType = { fallbackType: 2, Type: "Disconnect" };
                    }
                    // fallbackType = { fallbackType: 7, Type: "Parking" };
                  }
                  // Add the new fields to the originalData object
                  const newData = {
                    ...temp,
                    skills,
                    fallbackType,
                    queueIvrTransferMulti,
                    queueSkillTransferMulti,
                    dialOutMulti
                  };
  
                  temp = newData
                }
                const transformedAgentList = temp?.agents.flatMap(agent => {
                  if (agent.agentGroups.length === 0) {
                    return {
                      id: agent.id,
                      agentId: agent.agentId,
                      agentName: agent.agentName,
                      priority: agent.priority,
                      groupName: null,
                      groupId: null
                    };
                  } else {
                    return agent.agentGroups.map(group => ({
                      id: agent.id,
                      agentId: agent.agentId,
                      agentName: agent.agentName,
                      priority: agent.priority,
                      groupName: group.name,
                      groupId: group.id.toString()
                    }));
                  }
                });
                temp.agents = transformedAgentList;
                temp.agentSkillList = transformedAgentList;
  
                // let tempAgents = temp.agentSkillList
                // let agentListArray = {}
                // tempAgents.forEach(agents => {
                //   if (!agents.priority) return // exit (maybe add them to a "no_make" category)
                //   if (!agentListArray[agents.priority]) agentListArray[agents.priority] = [{ agentName: agents.agentName, id: agents.id, agentId: agents.agentId, groupName: agents.groupName || null, groupId: agents.groupId || null  }]
                //   else agentListArray[agents.priority].push({ agentName: agents.agentName, id: agents.id, agentId: agents.agentId, groupName: agents.groupName || null, groupId: agents.groupId || null  })
                // })
                // let newAgent = []
                // Object.keys(agentListArray).forEach(item => {
                //   let obj = { 'priority': item, 'agentList': agentListArray[item] }
                //   newAgent.push(obj)
                // })
                // temp.agentSkillList = newAgent
                // setOnclickRowSkillData(temp)


                if (temp.agents.length > 0) {
                  let tempAgents = temp.agents
                  let agentListArray = {}
                  tempAgents.forEach(agents => {
                    if (!agents.priority) return // exit (maybe add them to a "no_make" category)
                    if (!agentListArray[agents.priority]) agentListArray[agents.priority] = [{ agentName: agents.agentName, id: agents.id, agentId: agents.agentId, groupName: agents.groupName || null, groupId: agents.groupId || null }]
                    else agentListArray[agents.priority].push({ agentName: agents.agentName, id: agents.id, agentId: agents.agentId , groupName: agents.groupName || null, groupId: agents.groupId || null})
                  })
                  let newAgent = []
                  Object.keys(agentListArray).forEach(item => {
                    let obj = { 'priority': item, 'agentList': agentListArray[item] }
                    newAgent.push(obj)
                  })
                  temp.agents = newAgent
                  setOnclickRowSkillData(temp)
                }
                if (temp.agentSkillList.length > 0) {
                  let tempAgents = temp.agentSkillList
                  let agentListArray = {}
                  tempAgents.forEach(agents => {
                    if (!agents.priority) return // exit (maybe add them to a "no_make" category)
                    if (!agentListArray[agents.priority]) agentListArray[agents.priority] = [{ agentName: agents.agentName, id: agents.id, agentId: agents.agentId , groupName: agents.groupName || null, groupId: agents.groupId || null}]
                    else agentListArray[agents.priority].push({ agentName: agents.agentName, id: agents.id, agentId: agents.agentId, groupName: agents.groupName || null, groupId: agents.groupId || null })
                  })
                  let newAgent = []
                  Object.keys(agentListArray).forEach(item => {
                    let obj = { 'priority': item, 'agentList': agentListArray[item] }
                    newAgent.push(obj)
                  })
                  temp.agentSkillList = newAgent
                  setOnclickRowSkillData(temp)
                }
                else {
                  temp.agents = [{ 'priority': "-", 'agentList': [] }]
                  temp.agentSkillList = [{ 'priority': "-", 'agentList': [] }]
                  setOnclickRowSkillData(temp)
                }
                console.log("here coming after update in subAdmin", temp);

                //::::::::for SubAdmin:::::::://

              }else{

                //::::::::for Admin:::::::://
                let temp = structuredClone(resp?.Data[0])
                if (temp?.fallbackDetails === null) {
                  let fallbackType = { fallbackType: 2, Type: "Disconnect" };
                  const newData = {
                    ...temp,
                    fallbackType,
                  };
                  temp = newData;
                }
                if (temp?.fallbackDetails) {
                  // Parse the fallbackDetails string to JSON
                  const fallbackDetails = JSON.parse(temp.fallbackDetails);

                  // Create the skills array
                  const skills = fallbackDetails?.skills?.map(skill => {
                    let queueTimeSec = skill.queueTime;
                    let queueTimeMin = Math.floor(queueTimeSec / 60);
                    queueTimeSec %= 60;
                    return {
                      skills:
                      {
                        skillName: skill?.skillName,
                        id: skill?.id
                      },
                      multiskillqueueTimeOut_Min: queueTimeMin,
                      multiskillqueueTimeOut_Sec: queueTimeSec
                    };
                  });

                  let fallbackType = {};
                  let queueIvrTransferMulti = "";
                  let queueSkillTransferMulti = null;
                  let dialOutMulti = null;


                  if (fallbackDetails?.fallbackType === 1 || fallbackDetails?.fallbackType === "1") {
                    fallbackType = { fallbackType: 1, Type: "Dialout" };
                    dialOutMulti = dailoutList.find(item => item.dialOutName === fallbackDetails?.fallbackValue);
                  } else if (fallbackDetails?.fallbackType === 2 || fallbackDetails?.fallbackType === "2") {
                    fallbackType = { fallbackType: 2, Type: "Disconnect" };
                  } else if (fallbackDetails?.fallbackType === 3 || fallbackDetails?.fallbackType === "3") {
                    fallbackType = { fallbackType: 3, Type: "voicemail" };
                  } else if (fallbackDetails?.fallbackType === 4 || fallbackDetails?.fallbackType === "4") {
                    fallbackType = { fallbackType: 4, Type: "Skill" };
                    queueSkillTransferMulti = skillList.find(item => item.skillName === fallbackDetails?.fallbackValue);
                  } else if (fallbackDetails?.fallbackType === 5 || fallbackDetails?.fallbackType === "5") {
                    fallbackType = { fallbackType: 5, Type: "IVR" };
                    queueIvrTransferMulti = fallbackDetails.fallbackValue
                  } 
                  else if (fallbackDetails?.fallbackType === 7 || fallbackDetails?.fallbackType === "7") {
                    if(checkRoles('ROLE_PARKING')){
                      fallbackType = { fallbackType: 7, Type: "Parking" };
                    }else{
                      fallbackType = { fallbackType: 2, Type: "Disconnect" };
                    }
                    // fallbackType = { fallbackType: 7, Type: "Parking" };
                  }
                  const newData = {
                    ...temp,
                    skills,
                    fallbackType,
                    queueIvrTransferMulti,
                    queueSkillTransferMulti,
                    dialOutMulti
                  };

                  temp = newData
                }

                // let tempAgents = temp.agentSkillList
                // let agentListArray = {}
                // tempAgents.forEach(agents => {
                //   if (!agents.priority) return // exit (maybe add them to a "no_make" category)
                //   if (!agentListArray[agents.priority]) agentListArray[agents.priority] = [{ agentName: agents.agentName, id: agents.id, agentId: agents.agentId }]
                //   else agentListArray[agents.priority].push({ agentName: agents.agentName, id: agents.id, agentId: agents.agentId })
                // })
                // let newAgent = []
                // Object.keys(agentListArray).forEach(item => {
                //   let obj = { 'priority': item, 'agentList': agentListArray[item] }
                //   newAgent.push(obj)
                // })
                // temp.agentSkillList = newAgent
                // setOnclickRowSkillData(temp)

                let tempAgents = temp.agents
                let agentListArray = {}
                tempAgents.forEach(agents => {
                  if (!agents.priority) return // exit (maybe add them to a "no_make" category)
                  if (!agentListArray[agents.priority]) agentListArray[agents.priority] = [{ agentName: agents.agentName, id: agents.id, agentId: agents.agentId }]
                  else agentListArray[agents.priority].push({ agentName: agents.agentName, id: agents.id, agentId: agents.agentId })
                })
                let newAgent = []
                Object.keys(agentListArray).forEach(item => {
                  let obj = { 'priority': item, 'agentList': agentListArray[item] }
                  newAgent.push(obj)
                })
                temp.agentSkillList = newAgent
                temp.agents = newAgent
                setOnclickRowSkillData(temp)
                console.log("here i get the onUpdate by Admin ", temp);

                //::::::::for Admin:::::::://
              }
              // let temp = structuredClone(resp?.Data[0])
              // console.log("while update in updte", temp);
              // if (temp?.fallbackDetails === null) {
              //   let fallbackType = { fallbackType: 2, Type: "Disconnect" };
              //   const newData = {
              //     ...temp,
              //     fallbackType,
              //   };
              //   temp = newData;
              // }
              // if (temp?.fallbackDetails) {
              //   // Parse the fallbackDetails string to JSON
              //   const fallbackDetails = JSON.parse(temp.fallbackDetails);

              //   // Create the skills array
              //   const skills = fallbackDetails?.skills?.map(skill => {
              //     let queueTimeSec = skill.queueTime;
              //     let queueTimeMin = Math.floor(queueTimeSec / 60);
              //     queueTimeSec %= 60;
              //     return {
              //       skills:
              //       {
              //         skillName: skill?.skillName,
              //         id: skill?.id
              //       },
              //       multiskillqueueTimeOut_Min: queueTimeMin,
              //       multiskillqueueTimeOut_Sec: queueTimeSec
              //     };
              //   });

              //   let fallbackType = {};
              //   let queueIvrTransferMulti = "";
              //   let queueSkillTransferMulti = null;
              //   let dialOutMulti = null;


              //   if (fallbackDetails?.fallbackType === 1 || fallbackDetails?.fallbackType === "1") {
              //     fallbackType = { fallbackType: 1, Type: "Dialout" };
              //     dialOutMulti = dailoutList.find(item => item.dialOutName === fallbackDetails?.fallbackValue);
              //   } else if (fallbackDetails?.fallbackType === 2 || fallbackDetails?.fallbackType === "2") {
              //     fallbackType = { fallbackType: 2, Type: "Disconnect" };
              //   } else if (fallbackDetails?.fallbackType === 3 || fallbackDetails?.fallbackType === "3") {
              //     fallbackType = { fallbackType: 3, Type: "voicemail" };
              //   } else if (fallbackDetails?.fallbackType === 4 || fallbackDetails?.fallbackType === "4") {
              //     fallbackType = { fallbackType: 4, Type: "Skill" };
              //     queueSkillTransferMulti = skillList.find(item => item.skillName === fallbackDetails?.fallbackValue);
              //   } else if (fallbackDetails?.fallbackType === 5 || fallbackDetails?.fallbackType === "5") {
              //     fallbackType = { fallbackType: 5, Type: "IVR" };
              //     queueIvrTransferMulti = fallbackDetails.fallbackValue
              //   } 
              //   else if (fallbackDetails?.fallbackType === 7 || fallbackDetails?.fallbackType === "7") {
              //     if(checkRoles('ROLE_PARKING')){
              //       fallbackType = { fallbackType: 7, Type: "Parking" };
              //     }else{
              //       fallbackType = { fallbackType: 2, Type: "Disconnect" };
              //     }
              //     // fallbackType = { fallbackType: 7, Type: "Parking" };
              //   }
              //   // Add the new fields to the originalData object
              //   const newData = {
              //     ...temp,
              //     skills,
              //     fallbackType,
              //     queueIvrTransferMulti,
              //     queueSkillTransferMulti,
              //     dialOutMulti
              //   };

              //   temp = newData
              // }

              // console.log("here new ::::::::::::::22222222222222222222222:::::: when tranfer:::::::::::::::", temp);
              // let tempAgents = temp.agentSkillList
              // let agentListArray = {}
              // tempAgents.forEach(agents => {
              //   if (!agents.priority) return // exit (maybe add them to a "no_make" category)
              //   if (!agentListArray[agents.priority]) agentListArray[agents.priority] = [{ agentName: agents.agentName, id: agents.id, agentId: agents.agentId, groupName: agents.groupName || null, groupId: agents.groupId || null  }]
              //   else agentListArray[agents.priority].push({ agentName: agents.agentName, id: agents.id, agentId: agents.agentId, groupName: agents.groupName || null, groupId: agents.groupId || null  })
              // })
              // let newAgent = []
              // Object.keys(agentListArray).forEach(item => {
              //   let obj = { 'priority': item, 'agentList': agentListArray[item] }
              //   newAgent.push(obj)
              // })
              // temp.agentSkillList = newAgent
              // setOnclickRowSkillData(temp)
            })
            getSkill();
            showSuccessNotification(`Skill (${data.skillName}) has been Updated Successfully`);
            setLoading(false);

          }
          else {
            showErrorNotification(resp?.Message?.skillDetail2 || resp?.Message?.skillDetail3 || resp?.Message);
            setLoading(false);
            // showErrorNotification(JSON.stringify(resp.Message));
          }
        })
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));
          setLoading(false);
          console.log(e)
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
      setLoading(false);
      console.log(e)
    }
  };

  const MouseEnter = (props) => {
    const [anchorE1, setAnchorE1] = useState(false);
    const open = Boolean(anchorE1);
    const [openDelete, setDeleteDialog] = useState(false)
    const anchorRef = React.useRef();
    const [renameMode, setRenameMode] = useState(false);
    const [datas, setDatas] = useState();

    const onClickRename = () => {
      setAnchorE1(false);
      setRenameMode(true);
    };

    const morevertclick = () => {
      setTimeout(() => setAnchorE1(anchorRef?.current), 1)
      try {
        getSkillById(props.data.id)
          .then((resp) => {
            setDatas(resp.Data[0]);
          })
          .catch((e) => {
            setDatas({});
          });
      } catch (e) {
        showErrorNotification(JSON.stringify(e));
      }
    };

    const handleMenuClose = () => {
      setAnchorE1(false);
    };

    const deleteSkill = () => {
      setAnchorE1(false);
      deleteSkillsById(props.data.id)
      setDeleteDialog(false)
    };

    function openDeleteDialog() {
      setAnchorE1(false);
      setDeleteDialog(true)
    };

    const styleMenuItemButton = useMemo(
      () => ({
        "&.MuiButtonBase-root": {
          color: '#212121',
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          height: '18px',
          lineHeight: "14px",
        },
        '&:hover': {
          borderRadius: "8px",
        },
      }),
      []
    );

    return (
      <div className='oz-skill-grid-columns-container'>
        <label>{props.value}</label>
        {/* {authPage(AdminPages.SKILLS_EDIT) && checkRoles("ADMIN_ROLES") && <img className="gridMorevertIcon"
          onClick={morevertclick}
          src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
        >
        </img>
        } */}
        {(newAuthPages(NewAdminPages.SKILLS_PAGE) || newAuthSubAdminAccess(NewAdminPages.SKILLS_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.SKILLS_PAGE, 'Edit')) && checkRoles("ADMIN_ROLES") && <img className="gridMorevertIcon"
          onClick={morevertclick}
          src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
        >
        </img>
        }
        <div ref={anchorRef} >
          <Menu anchorEl={anchorE1} open={open} onClose={handleMenuClose} sx={{
            "& .MuiPaper-root": {
              backgroundColor: "white",
              height: "62px",
              width: "133px",
            },
            "& .MuiMenu-paper": {
              backgroundColor: "white",
              boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
              border: "0.0625rem solid #E6E5E6",
              borderRadius: "12px",
              marginLeft: "-1.1625rem",
            },
          }} >
            <MenuItem sx={{ ...styleMenuItemButton }} onClick={onClickRename} >Rename </MenuItem>
            <MenuItem sx={{ ...styleMenuItemButton, marginTop: "7px" }} onClick={openDeleteDialog} disabled={!(newAuthPages(NewAdminPages.SKILLS_PAGE) || newAuthSubAdminAccess(NewAdminPages.SKILLS_PAGE, 'Full'))}>Delete </MenuItem>
          </Menu>
        </div>
        {openDelete && <DeleteDialog title={"Delete Skill"} content={"This will delete this skill:" + props.data.skillName + " permanently. You cannot undo this action."} onClose={() => { setDeleteDialog(false) }
        } open={true} deleteFunction={deleteSkill} />}
        {
          renameMode &&
          <SkillForm onClose={() => { setRenameMode(false) }} data={datas} mode={"rename"} updateTransfer={updateSkills} updateSkill={getSkill} skillData={agentDataList} editAccess={(newAuthPages(NewAdminPages.SKILLS_PAGE) || newAuthSubAdminAccess(NewAdminPages.SKILLS_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.SKILLS_PAGE, 'Edit'))}/>
        }
      </div>
    );
  };

  const getSkillNameRenderer = (props) => {
    return (
      <Typography sx={{ color: '#3D8BF8', fontWeight: "400", fontSize: "12px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{props.value}</Typography>
    )
  };

  const SkillLocationRenderer = (props) => {
    return (
      <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{props.value}</span>
    );
  };

  const columnDefs = [
    {
      field: 'skillName',
      headerName: "Skill Name",
      cellRenderer: getSkillNameRenderer,
    },
    { field: 'skillLocation', headerName: "Skill Location", cellRenderer: SkillLocationRenderer },
    { field: 'record', headerName: "Record", },
    { field: 'queueSize', headerName: "Queue Size", },
    { field: 'queueTimeOut', headerName: "Queue Timeout", cellRenderer: MouseEnter },
  ];

  let buttonStyle = useMemo(
    () => ({
      borderRadius: "8px",
      height: "30px",
      fontSize: "14px",
      fontWeight: 400,
      width: '8rem',

    }),
    []
  );

  const showSkillForm = () => {
    setShowSkillsForm({ isNew: true, show: true });
  };

  const closeSkillForm = () => {
    setShowSkillsForm(false)
    setShowSkillsForm({ isNew: false, show: false });
  };

  async function getRowDataById(rowItem, dailoutList, skillList) {
    setShowSkillsForm({ isNew: false, show: true });
    setOnclickRowSkillData({});
    setLoading(true);
    try {
      getSkillById(rowItem.data.id)
        .then((resp) => {
          if(localStorage.getItem('isAdmin') === false || localStorage.getItem('isAdmin') === 'false'){
            //:::::::::::;here with SubAdmin::::::://
            let temp = structuredClone(resp?.Data[0])
            if (temp?.fallbackDetails === null) {
              let fallbackType = { fallbackType: 2, Type: "Disconnect" };
              const newData = {
                ...temp,
                fallbackType,
              };
              temp = newData;
            }
            if (temp?.fallbackDetails) {
              // Parse the fallbackDetails string to JSON
              const fallbackDetails = JSON.parse(temp.fallbackDetails);

              // Create the skills array
              const skills = fallbackDetails?.skills?.map(skill => {
                let queueTimeSec = skill.queueTime;
                let queueTimeMin = Math.floor(queueTimeSec / 60);
                queueTimeSec %= 60;
                return {
                  skills:
                  {
                    skillName: skill?.skillName,
                    id: skill?.id
                  },
                  multiskillqueueTimeOut_Min: queueTimeMin,
                  multiskillqueueTimeOut_Sec: queueTimeSec
                };
              });

              let fallbackType = {};
              let queueIvrTransferMulti = "";
              let queueSkillTransferMulti = null;
              let dialOutMulti = null;


              if (fallbackDetails?.fallbackType === 1 || fallbackDetails?.fallbackType === "1") {
                fallbackType = { fallbackType: 1, Type: "Dialout" };
                dialOutMulti = dailoutList.find(item => item.dialOutName === fallbackDetails.fallbackValue);
              } else if (fallbackDetails?.fallbackType === 2 || fallbackDetails?.fallbackType === "2") {
                fallbackType = { fallbackType: 2, Type: "Disconnect" };
              } else if (fallbackDetails?.fallbackType === 3 || fallbackDetails?.fallbackType === "3") {
                fallbackType = { fallbackType: 3, Type: "voicemail" };
              } else if (fallbackDetails?.fallbackType === 4 || fallbackDetails?.fallbackType === "4") {
                fallbackType = { fallbackType: 4, Type: "Skill" };
                // queueSkillTransfer = fallbackDetails.fallbackValue
                queueSkillTransferMulti = skillList.find(item => item.skillName === fallbackDetails?.fallbackValue);
              } else if (fallbackDetails?.fallbackType === 5 || fallbackDetails?.fallbackType === "5") {
                fallbackType = { fallbackType: 5, Type: "IVR" };
                queueIvrTransferMulti = fallbackDetails.fallbackValue
              }
              else if (fallbackDetails?.fallbackType === 7 || fallbackDetails?.fallbackType === "7") {
                if(checkRoles('ROLE_PARKING')){
                  fallbackType = { fallbackType: 7, Type: "Parking" };
                }else{
                  fallbackType = { fallbackType: 2, Type: "Disconnect" };
                }
                // fallbackType = { fallbackType: 7, Type: "Parking" };
              }
              const newData = {
                ...temp,
                skills,
                fallbackType,
                queueSkillTransferMulti,
                queueIvrTransferMulti,
                dialOutMulti
              };

              temp = newData
            }

            // const transformedAgentSkillList = temp?.agentSkillList.flatMap(agent => {
            //   if (agent.agentGroups.length === 0) {
            //     return {
            //       id: agent.id,
            //       skillName: agent.skillName,
            //       agentId: agent.agentId,
            //       agentName: agent.agentName,
            //       priority: agent.priority,
            //       groupName: null,
            //       groupId: null
            //     };
            //   } else {
            //     return agent.agentGroups.map(group => ({
            //       id: agent.id,
            //       skillName: agent.skillName,
            //       agentId: agent.agentId,
            //       agentName: agent.agentName,
            //       priority: agent.priority,
            //       groupName: group.name,
            //       groupId: group.id.toString()
            //     }));
            //   }
            // });

            const transformedAgentList = temp?.agents.flatMap(agent => {
              if (agent.agentGroups.length === 0) {
                return {
                  id: agent.id,
                  agentId: agent.agentId,
                  agentName: agent.agentName,
                  priority: agent.priority,
                  groupName: null,
                  groupId: null
                };
              } else {
                return agent.agentGroups.map(group => ({
                  id: agent.id,
                  agentId: agent.agentId,
                  agentName: agent.agentName,
                  priority: agent.priority,
                  groupName: group.name,
                  groupId: group.id.toString()
                }));
              }
            });
            
            // temp.agentSkillList = transformedAgentSkillList;
            // temp.agents = transformedAgentList;
            temp.agentSkillList = transformedAgentList;
            temp.agents = transformedAgentList;

            if (temp.agents.length > 0) {
              let tempAgents = temp.agents
              let agentListArray = {}
              tempAgents.forEach(agents => {
                if (!agents.priority) return // exit (maybe add them to a "no_make" category)
                if (!agentListArray[agents.priority]) agentListArray[agents.priority] = [{ agentName: agents.agentName, id: agents.id, agentId: agents.agentId, groupName: agents.groupName || null, groupId: agents.groupId || null }]
                else agentListArray[agents.priority].push({ agentName: agents.agentName, id: agents.id, agentId: agents.agentId , groupName: agents.groupName || null, groupId: agents.groupId || null})
              })
              let newAgent = []
              Object.keys(agentListArray).forEach(item => {
                let obj = { 'priority': item, 'agentList': agentListArray[item] }
                newAgent.push(obj)
              })
              temp.agents = newAgent
              setOnclickRowSkillData(temp)
              setLoading(false);
              console.log(temp)
            }
            if (temp.agentSkillList.length > 0) {
              let tempAgents = temp.agentSkillList
              let agentListArray = {}
              tempAgents.forEach(agents => {
                if (!agents.priority) return // exit (maybe add them to a "no_make" category)
                if (!agentListArray[agents.priority]) agentListArray[agents.priority] = [{ agentName: agents.agentName, id: agents.id, agentId: agents.agentId , groupName: agents.groupName || null, groupId: agents.groupId || null}]
                else agentListArray[agents.priority].push({ agentName: agents.agentName, id: agents.id, agentId: agents.agentId, groupName: agents.groupName || null, groupId: agents.groupId || null })
              })
              let newAgent = []
              Object.keys(agentListArray).forEach(item => {
                let obj = { 'priority': item, 'agentList': agentListArray[item] }
                newAgent.push(obj)
              })
              temp.agentSkillList = newAgent
              setOnclickRowSkillData(temp)
              setLoading(false);
              console.log(temp)
            }
            else {
              temp.agents = [{ 'priority': "-", 'agentList': [] }]
              temp.agentSkillList = [{ 'priority': "-", 'agentList': [] }]
              setOnclickRowSkillData(temp)
              setLoading(false);
            }
            console.log("here coming after getByID in subAdmin", temp);

            //:::::::::::;here with SubAdmin::::::://
          }else{

            //:::::::::::;here with Admin::::::://

            let temp = structuredClone(resp?.Data[0])
            if (temp?.fallbackDetails === null) {
              let fallbackType = { fallbackType: 2, Type: "Disconnect" };
              const newData = {
                ...temp,
                fallbackType,
              };
              temp = newData;
            }
            if (temp?.fallbackDetails) {
              // Parse the fallbackDetails string to JSON
              const fallbackDetails = JSON.parse(temp.fallbackDetails);
  
              // Create the skills array
              const skills = fallbackDetails?.skills?.map(skill => {
                let queueTimeSec = skill.queueTime;
                let queueTimeMin = Math.floor(queueTimeSec / 60);
                queueTimeSec %= 60;
                return {
                  skills:
                  {
                    skillName: skill?.skillName,
                    id: skill?.id
                  },
                  multiskillqueueTimeOut_Min: queueTimeMin,
                  multiskillqueueTimeOut_Sec: queueTimeSec
                };
              });
  
              let fallbackType = {};
              let queueIvrTransferMulti = "";
              let queueSkillTransferMulti = null;
              let dialOutMulti = null;
  
  
              if (fallbackDetails?.fallbackType === 1 || fallbackDetails?.fallbackType === "1") {
                fallbackType = { fallbackType: 1, Type: "Dialout" };
                dialOutMulti = dailoutList.find(item => item.dialOutName === fallbackDetails.fallbackValue);
              } else if (fallbackDetails?.fallbackType === 2 || fallbackDetails?.fallbackType === "2") {
                fallbackType = { fallbackType: 2, Type: "Disconnect" };
              } else if (fallbackDetails?.fallbackType === 3 || fallbackDetails?.fallbackType === "3") {
                fallbackType = { fallbackType: 3, Type: "voicemail" };
              } else if (fallbackDetails?.fallbackType === 4 || fallbackDetails?.fallbackType === "4") {
                fallbackType = { fallbackType: 4, Type: "Skill" };
                // queueSkillTransfer = fallbackDetails.fallbackValue
                queueSkillTransferMulti = skillList.find(item => item.skillName === fallbackDetails?.fallbackValue);
              } else if (fallbackDetails?.fallbackType === 5 || fallbackDetails?.fallbackType === "5") {
                fallbackType = { fallbackType: 5, Type: "IVR" };
                queueIvrTransferMulti = fallbackDetails.fallbackValue
              }
              else if (fallbackDetails?.fallbackType === 7 || fallbackDetails?.fallbackType === "7") {
                if(checkRoles('ROLE_PARKING')){
                  fallbackType = { fallbackType: 7, Type: "Parking" };
                }else{
                  fallbackType = { fallbackType: 2, Type: "Disconnect" };
                }
                // fallbackType = { fallbackType: 7, Type: "Parking" };
              }
              // Add the new fields to the originalData object
              const newData = {
                ...temp,
                skills,
                fallbackType,
                queueSkillTransferMulti,
                queueIvrTransferMulti,
                dialOutMulti
              };
  
              temp = newData
            }
  
            temp.agentSkillList = temp.agents;
  
            if (temp.agents.length > 0) {
              let tempAgents = temp.agents
              let agentListArray = {}
              tempAgents.forEach(agents => {
                if (!agents.priority) return // exit (maybe add them to a "no_make" category)
                if (!agentListArray[agents.priority]) agentListArray[agents.priority] = [{ agentName: agents.agentName, id: agents.id, agentId: agents.agentId }]
                else agentListArray[agents.priority].push({ agentName: agents.agentName, id: agents.id, agentId: agents.agentId })
              })
              let newAgent = []
              Object.keys(agentListArray).forEach(item => {
                let obj = { 'priority': item, 'agentList': agentListArray[item] }
                newAgent.push(obj)
              })
              temp.agents = newAgent
              setOnclickRowSkillData(temp)
              setLoading(false);
              console.log(temp)
            }
            if (temp.agentSkillList.length > 0) {
              let tempAgents = temp.agentSkillList
              let agentListArray = {}
              tempAgents.forEach(agents => {
                if (!agents.priority) return // exit (maybe add them to a "no_make" category)
                if (!agentListArray[agents.priority]) agentListArray[agents.priority] = [{ agentName: agents.agentName, id: agents.id, agentId: agents.agentId }]
                else agentListArray[agents.priority].push({ agentName: agents.agentName, id: agents.id, agentId: agents.agentId })
              })
              let newAgent = []
              Object.keys(agentListArray).forEach(item => {
                let obj = { 'priority': item, 'agentList': agentListArray[item] }
                newAgent.push(obj)
              })
              temp.agentSkillList = newAgent
              setOnclickRowSkillData(temp)
              setLoading(false);
              console.log(temp)
            }
            else {
              temp.agents = [{ 'priority': "-", 'agentList': [] }]
              temp.agentSkillList = [{ 'priority': "-", 'agentList': [] }]
              setOnclickRowSkillData(temp)
              setLoading(false);
            }
            console.log("here coming after getByID in Admin", temp);

            //:::::::::::;here with Admin::::::://
          }
        }
        )
        .catch((e) => {
          setOnclickRowSkillData({});
          setLoading(false);
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
      setLoading(false);
    }

  }

  async function onClickRow(rowItem) {
    getRowDataById(rowItem, dailoutList, skillList)
  };
  return (
    <div className="oz-admin-Skill-container" >
      {<div >
        <Typography sx={{
          paddingLeft: '5px',
          fontStyle: 'normal',
          fontWeight: 400, fontfamily: 'Roboto',
          fontSize: '12px',
          lineHeight: '14px', paddingBottom: '5px'
        }} flexGrow={1} alignSelf='center'>Configurations</Typography>
      </div>}
      {<Stack direction="row" sx={{ marginBottom: 2, paddingLeft: '3px', }} >
        <Typography sx={{
          fontfamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '28px',
        }} flexGrow={1} alignSelf='center'> Skills</Typography>
        {/* {authPage(AdminPages.SKILLS_EDIT) && <Button
          sx={buttonStyle}
          variant="contained"
          startIcon={<AddBoxOutlinedIcon />}
          onClick={showSkillForm} >
          New Skill
        </Button>} */}
        {(newAuthPages(NewAdminPages.SKILLS_PAGE) || newAuthSubAdminAccess(NewAdminPages.SKILLS_PAGE, 'Full')) && <Button
          sx={buttonStyle}
          variant="contained"
          startIcon={<AddBoxOutlinedIcon />}
          onClick={showSkillForm} >
          New Skill
        </Button>}
      </Stack>}
      {(showSkillsForm.show && showSkillsForm.isNew) && <SkillForm onClose={closeSkillForm} skillsCallBack={skillsCallBack} mode={"Create"} updateTransfer={updateSkills} agentDataList={agentDataList} />}
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        (<>
          {(showSkillsForm.show && !showSkillsForm.isNew) && <SkillForm onClose={closeSkillForm} data={onclickRowSkillData} skillsCallBack={skillsCallBack} updateTransfer={updateSkills} agentDataList={agentDataList} editAccess={(newAuthPages(NewAdminPages.SKILLS_PAGE) || newAuthSubAdminAccess(NewAdminPages.SKILLS_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.SKILLS_PAGE, 'Edit'))}/>}
        </>
        )
      }
      <OZGridToolbar title='List of Skills' searchPlaceholder='Skill Name' rowCount={skillTableData.length} dataGridRef={gridRef} fileName='Skills' />
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        (<>
          {<OZDataGrid
            height='26.3rem'
            setGridRef={setGridRef}
            data={skillTableData}
            columns={columnDefs}
            rowClickCallback={onClickRow}
          />}
        </>
        )
      }
    </div>
  );
};
export default Skill;