import React, { useState, useMemo } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Divider, Grid, Typography, TextField, Tooltip } from '@mui/material';
import OZInput from '../../../components/admin/common/OZInput';
import OZDrawer from '../../../components/admin/common/OZDrawer/OZDrawer';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import OZDialog from '../../../components/admin/common/OZDialog';
import { addTemplate, deleteTemplateById, updateTemplate } from '../../../services/api-service';
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from '../../../services/page-service';


function SMSView(props) {
    const TemplateFieldsView = (props) => {
        return (<>
            <div className="oz-Template-view-lablel-container">
                <label className='oz-template-view-label'>{props.label}</label>
                {
                    (props.data.length !== 0) ?
                        <label className='oz-template-view-value'>{props.data} </label> :
                        <label className='oz-template-view-value'> - </label>
                }
            </div>
        </>
        )
    }

    return (
        <Grid direction={"column"} container={true} spacing={1}>
            {props?.data?.text &&
                <TemplateFieldsView data={props?.data?.text} label={" Template Text *"} />}
            {props?.data?.templateId &&
                <TemplateFieldsView data={props?.data?.templateId} label={"Template ID *"} />}
            {props?.data?.entityId &&
                <TemplateFieldsView data={props?.data?.entityId} label={"Entity Id *"} />}
            {props?.data?.type &&
                <TemplateFieldsView data={props?.data?.type} label={"Type *"} />}
        </Grid>
    )
}
const SMSTemplateForm = (props) => {
    let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add
    const validationSchema = useMemo(() => (yup.object({
        name: yup
            .string('Name is required')
            .min(2, "Name should not be empty and it should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters.")
            .max(50, "Name should not be empty and it should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters.")
            .matches(/^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/, "Name should not be empty and it should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters.")
            .required(' Name is required'),
        text: yup
            .string("Email Subject")
            .matches(/^(?!\s+$).{1,500}$/, "Text should not be empty and allows max of 500 characters.")
            .required('Text is required'),
        entityId: yup
            .string("Entity ID")
            .min(2, "Entity Id should be between 2 to 20 alphanumeric characters long and allows special characters like _ and it cannot start and end with Special characters.")
            .max(20, "Entity Id should be between 2 to 20 alphanumeric characters long and allows special characters like _ and it cannot start and end with Special characters.")
            .matches(/^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/, "Entity Id should be between 2 to 20 alphanumeric characters long and allows special characters like _ and it cannot start and end with Special characters.")
            .required(' Entity ID is required'),
        templateId: yup
            .string("Template ID")
            .min(2, "Template Id should be between 2 to 20 alphanumeric characters long and allows special characters like _ and it cannot start and end with Special characters.")
            .max(20, "Template Id should be between 2 to 20 alphanumeric characters long and allows special characters like _ and it cannot start and end with Special characters.")
            .matches(/^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/, "Template Id should be between 2 to 20 alphanumeric characters long and allows special characters like _ and it cannot start and end with Special characters.")
            .required(' Template ID is required'),

    })), []);

    const [open, setState] = useState(true)
    const [smsTemplateFormMode, setSmsTemplateFormMode] = useState(initMode);
    const [smsTemplateanchorE1, setSmsTemplateAnchorE1] = React.useState(false);
    const anchorRef = React.useRef();
    const opens = Boolean(smsTemplateanchorE1);
    const [openDeleteDailog, setDeleteDialog] = useState(false)
    const [valid, setValid] = React.useState(true);

    const morevertclick = () => {
        setTimeout(() => setSmsTemplateAnchorE1(anchorRef?.current), 1);
    };
    const closeSMSTemplateForm = () => {
        props.onClose();
        setState(false)
    }
    const EditSMSTemplateForm = () => {
        setSmsTemplateFormMode(ADMIN_FORM_MODES.edit);
    }
    const handleMenuClose = () => {
        setSmsTemplateAnchorE1(null);
    };
    const onClickDiscardButton = () => {
        setSmsTemplateFormMode(ADMIN_FORM_MODES.view)

    };

    function openDeleteDialog() {
        setSmsTemplateAnchorE1(false);
        ; setDeleteDialog(true)
    }

    const deletesms = () => {
        setSmsTemplateAnchorE1(false);
        deleteSmsById(props.data.id)
        setDeleteDialog(false)
    };

    let buttonStyle = useMemo(
        () => ({
            borderRadius: "0.5rem",
            height: "2.75rem",
            fontSize: "0.875rem",
            fontWeight: 400,
        }),
        []
    );

    async function deleteSmsById() {
        try {
            deleteTemplateById(props.data.id)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeSMSTemplateForm();
                        props.smsTemplate();
                        showSuccessNotification(resp.Message)

                    } else {
                        showErrorNotification(JSON.stringify(resp.Message));
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    async function addSms(data) {
        data.type = "sms"
        try {
            addTemplate(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        showSuccessNotification(`Template ${data.name} has been added Successfully`);
                        props.smsTemplate();
                        closeSMSTemplateForm();
                    }
                    else {
                        showErrorNotification(resp?.Message);
                        // showErrorNotification(JSON.stringify(resp.Message));
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    async function updateSms(data) {
        data.type = "sms"
        try {
            updateTemplate(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeSMSTemplateForm();
                        props.smsTemplate();
                        showSuccessNotification(`Template ${data.name} has been updated Successfully`);
                    }
                    else {
                        showErrorNotification(resp?.Message);
                        // showErrorNotification(JSON.stringify(resp.Message));
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    const getValidationInViewMode = (e, value) => {
        props.data.name = value;
        const regex = /^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/;
        const isMatch = regex.test(value);

        const digitCount = value?.length;

        // Check the conditions for validation
        const isValidLength = value.length >= 2 && value.length <= 50;
        const isValidDigitCount = digitCount >= 2 && digitCount <= 50;

        // Final validation check
        if (isMatch && isValidLength && isValidDigitCount) {
            setValid(true);
        } else {
            setValid(false);
        }
    }

    let styleEditButton = useMemo(() => ({
        fontWeight: '400',
        fontSize: '14px',
        color: '#3D8BF8',
    }),
        []
    );
    const styleListItemButton = useMemo(
        () => ({
            padding: "0px",
            alignSelf: "center",
            paddingLeft: "10px",
            "&.MuiButtonBase-root": {
                color: '#212121',
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                height: '18px',
                lineHeight: "14px",
            },
            '&:hover': {
                borderRadius: "8px",
            },
        }),
        []
    );

    const DataForm = (props) => {
        let initData = props.data || {
            name: '',
            text: '',
            type: [],
            templateId: '',
            entityId: ''
        };

        let style1 = useMemo(() => ({
            width: '600px',
            height: "20rem",
            paddingtop: "10px"

        }), [])

        let style2 = useMemo(() => ({
            maxHeight: '76vh',
            overflowY: 'auto',
            padding: '5px'
        }), [])

        return (
            <div className='oz-SMS-add-form'>
                <Formik initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        let data = structuredClone(values);
                        // let newText = "";
                        // newText = data?.text?.trim();

                        if (data?.text === "" || data?.text?.length > 500) {
                            showErrorNotification("Text should not be empty and allows max of 500 characters.");
                            return;
                        } else {
                            if (smsTemplateFormMode === ADMIN_FORM_MODES.add) {
                                addSms(data);
                            }
                            if (smsTemplateFormMode !== ADMIN_FORM_MODES.add) {
                                if (valid === true) {
                                    updateSms(data);
                                }
                            }
                        }
                    }}>

                    {props => (
                        <form onSubmit={props.handleSubmit} id='smsForm' className='ozcw-scrollbar' style={smsTemplateFormMode === ADMIN_FORM_MODES.add ? style1 :
                            style2}>
                            <Grid container spacing={2} marginTop='1px'>
                                {smsTemplateFormMode === ADMIN_FORM_MODES.add && <Grid item xs={12} sm={6} md={4} lg={12} marginRight="1px" >
                                    <OZInput
                                        name="name"
                                        label="Template Name *"
                                        placeholder="Name"

                                    />
                                </Grid>}
                                {(smsTemplateFormMode === ADMIN_FORM_MODES.add || smsTemplateFormMode === ADMIN_FORM_MODES.edit) && (<Grid item xs={12} sm={6} md={4} lg={12} marginRight="1px" >
                                    <OZInput
                                        name="text"
                                        label="Template Text *"
                                        placeholder="Email Subject"
                                    />
                                </Grid>)}
                                {(smsTemplateFormMode === ADMIN_FORM_MODES.add || smsTemplateFormMode === ADMIN_FORM_MODES.edit) && (
                                    <Grid container spacing={2} direction={'row'} >
                                        <Grid item xs={12} sm={12} md={12} lg={12} marginLeft="15px" marginTop='15px' >
                                            <OZInput
                                                name="entityId"
                                                label="Entity ID *"
                                                placeholder="Entity ID"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} marginLeft="15px">
                                            <OZInput
                                                name="templateId"
                                                label="Template ID *"
                                                placeholder="Template ID"
                                            />
                                        </Grid>
                                    </Grid>)}
                            </Grid>
                        </form>)}
                </Formik>

                {smsTemplateFormMode === ADMIN_FORM_MODES.edit && (
                    <div className='oz-template-drawer-buttons'>
                        <Grid item marginRight={'10px'} >
                            <Button disabled={smsTemplateFormMode === ADMIN_FORM_MODES.view} sx={{ ...buttonStyle, }} variant='outlined' onClick={closeSMSTemplateForm}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item marginRight={'10px'}>
                            <Button
                                disabled={smsTemplateFormMode === ADMIN_FORM_MODES.view}
                                sx={{ ...buttonStyle, }}
                                variant="contained"
                                type="submit"
                                form='smsForm'
                            >
                                Save Changes
                            </Button>
                        </Grid>
                    </div>)}
            </div >
        )
    };

    return (
        <div>
            <div>
                {smsTemplateFormMode === ADMIN_FORM_MODES.add && (
                    <OZDialog
                        title="Template"
                        formType="template"
                        subTitle=" Create SMS Template"
                        open={open}
                        mode={smsTemplateFormMode}
                        saveButtonProps={{
                            form: "smsForm",
                            type: 'submit',
                            label: 'save'
                        }}
                        onClose={closeSMSTemplateForm}
                    >
                        <Divider style={{ color: "#E6E5E6" }}></Divider>
                        {smsTemplateFormMode === ADMIN_FORM_MODES.add && <DataForm />}
                    </OZDialog>
                )}
            </div>
            {smsTemplateFormMode !== ADMIN_FORM_MODES.add && (
                <OZDrawer
                    open={open}
                    formType="template"
                    title={
                        <div className='oz-template-drawer-textdeild-container'>
                            <div className='oz-template-drawer-title-container'>
                                {/* {smsTemplateFormMode !== ADMIN_FORM_MODES.edit && authPage(AdminPages.TEMPLATES_EDIT) && <img className='oz-template-view-morevert-img' ref={anchorRef}
                                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                                    alt="edit"
                                    onClick={morevertclick}
                                ></img>} */}
                                {smsTemplateFormMode !== ADMIN_FORM_MODES.edit && (newAuthPages(NewAdminPages.TEMPLATES_PAGE) || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit')) && <img className='oz-template-view-morevert-img' ref={anchorRef}
                                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                                    alt="edit"
                                    onClick={morevertclick}
                                ></img>}
                                {smsTemplateFormMode !== ADMIN_FORM_MODES.edit &&
                                    <Tooltip title={props?.data?.name}>
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: '18px',
                                                alignSelf: 'center',
                                                // marginLeft: authPage(AdminPages.TEMPLATES_EDIT) ? "0px" : "22px",
                                                marginLeft: '0px',
                                                maxWidth: "400px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"
                                            }}
                                        >
                                            {props?.data?.name}
                                        </Typography></Tooltip>}

                                {smsTemplateFormMode === ADMIN_FORM_MODES.edit &&
                                    <TextField
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: "none",
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                fontWeight: 600,
                                                fontSize: "18px",
                                                color: "#212121",
                                                borderRadius: '8px',
                                                backgroundColor: '#ffff',
                                                border: "1px solid #D6D6D7",
                                                height: '44px',
                                                '&:hover': {
                                                    border: "1px solid #D6D6D7"
                                                },
                                            },
                                        }}
                                        defaultValue={props?.data?.name}
                                        fullWidth
                                        // onChange={(e) => { props.data.name = e.target.value }}
                                        onChange={(e) => getValidationInViewMode(e, e.target.value)}
                                        error={!valid}
                                        helperText={!valid ? "Name should not be empty and it should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters." : ""}
                                    />
                                }
                                <div >
                                    <Menu
                                        anchorEl={smsTemplateanchorE1}
                                        open={opens}
                                        onClose={handleMenuClose}
                                        sx={{
                                            "& .MuiPaper-root": {
                                                backgroundColor: "white",
                                                height: "37px",
                                                width: "133px",
                                            },
                                            "& .MuiMenu-paper": {
                                                backgroundColor: "white",
                                                boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                                                border: "0.0625rem solid #E6E5E6",
                                                borderRadius: "12px",
                                                marginLeft: "-8.9625rem",
                                                marginTop: '-1.5rem'
                                            },
                                        }}
                                    >
                                        <MenuItem sx={{ ...styleListItemButton }} disabled={newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit')} onClick={openDeleteDialog}>Delete</MenuItem>
                                    </Menu>
                                </div>
                            </div>
                            {openDeleteDailog &&
                                <DeleteDialog
                                    title={"Delete SMS Template"}
                                    content={`This will delete (${props.data.name}) SMS Template permanently. You cannot undo this action.`}
                                    onClose={() => { setDeleteDialog(false) }}
                                    open={openDeleteDailog}
                                    deleteFunction={deletesms}
                                />
                            }
                            <div className='oz-template-drawer-label-container'>
                                <Typography marginLeft={smsTemplateFormMode === ADMIN_FORM_MODES.edit ? "2px" : "23px"} marginBottom={smsTemplateFormMode === ADMIN_FORM_MODES.edit ? "0px" : "3px"} marginTop={smsTemplateFormMode === ADMIN_FORM_MODES.edit ? "5px" : "2px"} style={{ color: "#99A0A8", fontSize: '14px' }}> SMS Template</Typography>
                                {smsTemplateFormMode === ADMIN_FORM_MODES.edit && (<Typography
                                    size="small"
                                    variant="text"
                                    sx={{
                                        ...styleEditButton,
                                        marginLeft: "408px",
                                        marginTop: "5px",
                                        position: "absolute",
                                        cursor: "pointer"
                                    }}
                                    onClick={onClickDiscardButton}
                                >
                                    Discard
                                </Typography>)}
                            </div>
                        </div>
                    }
                    onClose={closeSMSTemplateForm}
                    onEdit={EditSMSTemplateForm}
                    mode={smsTemplateFormMode}
                    hideEditIcon={newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'View')}
                >
                    {smsTemplateFormMode !== ADMIN_FORM_MODES.edit && (
                        <SMSView data={props.data} />
                    )}
                    {smsTemplateFormMode === ADMIN_FORM_MODES.edit && <DataForm data={props.data} />}
                </OZDrawer>
            )}

        </div>
    );
};
export default SMSTemplateForm;
