import * as React from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material';
import ozonetelTheme from './ozonetel-theme/theme';
import Wireframe from './pages/Wireframe/Wireframe';
import { OZContextProvider } from './contexts/OZContext';
import { SnackbarProvider } from 'notistack';
import Fade from '@mui/material/Fade'
// console.log = console.warn = console.error = () => { };
function App() {

	React.useEffect(() => {
		const updateFavicon = () => {
			const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
			if (window.location.href.includes('engage')) {
				link.href = `${process.env.PUBLIC_URL}/engage192.png`;
			} else if (window.location.href.includes('mavenir')) {
				link.href = `${process.env.PUBLIC_URL}/favicon.ico`;
			} else if (window.location.href.includes('crexendocx')) {
				link.href = `${process.env.PUBLIC_URL}/crexendocx192.png`;
			} else {
				link.href = `${process.env.PUBLIC_URL}/favicon.ico`;
			}
		};

		updateFavicon();

		// Listen to location changes
		window.addEventListener('popstate', updateFavicon);

		return () => {
			// Clean up the event listener
			window.removeEventListener('popstate', updateFavicon);
		};

	}, []);


	return (
		<ThemeProvider theme={ozonetelTheme}>
			<SnackbarProvider maxSnack={3} TransitionComponent={Fade} anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}>
				<OZContextProvider>
					<Wireframe></Wireframe>
				</OZContextProvider>
			</SnackbarProvider>
		</ThemeProvider>
	);
}

export default App;
