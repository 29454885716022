//const DateFormat = "YYYY-MM-DD";
//const DateTimeFormat = "YYYY-MM-DD HH:mm:ss";

const DateFormat = "DD-MM-yyyy";
const DateFormatCalenderInHistoricReports = "DD-MM-YYYY";
const DateTimeFormat = "DD-MM-yyyy HH:mm:ss";
const SystemDateFormat = "yyyy-MM-DD";
// Test-properties (local)
// const UPDATE_PASSWORD_URL = 'https://10.233.17.168:9092/updatepassword';
// const FORGOT_PASSWORD_URL = 'https://10.233.17.168:9092/resetpassword';
// const LOGIN_URL = 'http://10.233.17.168:9092/auth/login';
// const BASE_URL = 'http://10.233.17.168:9092/dashboardApi';
// const BASE_API_URL = 'http://10.233.17.168:9092/reportApi/endpoint';
// const LIVE_BASE_URL = 'http://10.233.17.168:9092/liveReportApi';
// const ADMIN_BASE_URL = 'http://10.233.17.168:9092/ca-admin-Api/CloudAgentAPI';
// const CRM_REDIRECT_URL = "https://crm.auth.ozonetel.com/auth/zoho/integration";
// const AGENT_LOGIN_URL = 'https://10.233.17.203:8088/login';
// const IVR_FLOW_URL = 'https://admin-awsdev1.cloudagent.in/ivrdd';
// const APP_URL = 'http://kookoo_apps/DDParser/v1/index.php?tag_id=start&flow_id=';
// const IVR_FLOW_URL = 'https://beta-ivr.ccaas.ozonetel.com/ivrdd';
const LOGIN_PAGE_BACKGROUND_IMG_URL = "https://s3.ap-south-1.amazonaws.com/ca.cdn/"

// US-Prod
// const UPDATE_PASSWORD_URL = 'https://api.cxhub.ozonetel.com/updatepassword';
// const FORGOT_PASSWORD_URL = 'https://api.cxhub.ozonetel.com/resetpassword';
// const LOGIN_URL = 'https://api.cxhub.ozonetel.com/auth/login';
// const BASE_URL = 'https://api.cxhub.ozonetel.com/dashboardApi';
// const BASE_API_URL = 'https://api.cxhub.ozonetel.com/reportApi/endpoint';
// const LIVE_BASE_URL = 'https://api.cxhub.ozonetel.com/liveReportApi';
// const ADMIN_BASE_URL = 'https://api.cxhub.ozonetel.com/ca-admin-Api/CloudAgentAPI';
// const AGENT_LOGIN_URL = 'https://10.230.32.27:8088/login'
// const CRM_REDIRECT_URL = "https://crm.auth.ozonetel.com/auth/zoho/integration";
// const IVR_FLOW_URL = 'https://beta-ivr.ccaas.ozonetel.com/ivrdd';
// const APP_URL = 'http://kookoo_apps/DDParser/v1/index.php?tag_id=start&flow_id=';
// const IMAGE_UPLOAD_URL = 'https://api.ccaas.ozonetel.com';

// Prod-properties (InCcaas)
// const UPDATE_PASSWORD_URL = 'https://api.cx.dev.ozonetel.com/updatepassword';
// const FORGOT_PASSWORD_URL = 'https://api.cx.dev.ozonetel.com/resetpassword';
// const LOGIN_URL = 'https://api.cx.dev.ozonetel.com/auth/login';
// const BASE_URL = 'https://api.cx.dev.ozonetel.com/dashboardApi';
// const BASE_API_URL = 'https://api.cx.dev.ozonetel.com/reportApi/endpoint';
// const LIVE_BASE_URL = 'https://api.cx.dev.ozonetel.com/liveReportApi';
// const ADMIN_BASE_URL = 'http://localhost:8086/CloudAgentAPI';
// const AGENT_LOGIN_URL = 'https://10.230.32.27:8088/login'
// const CRM_REDIRECT_URL = "https://crm.auth.ozonetel.com/auth/zoho/integration";
// const IVR_FLOW_URL = 'https://in1-ccaas-admin.ozonetel.com/ivrdd';
// const APP_URL = 'http://kookoo_apps/DDParser/v1/index.php?tag_id=start&flow_id=';
// const IMAGE_UPLOAD_URL = 'https://in1-ccaas-api.ozonetel.com';


// Pre-Prod 
// const UPDATE_PASSWORD_URL = 'https://api.preprod-cx.ozonetel.com/updatepassword';
// const FORGOT_PASSWORD_URL = 'https://api.preprod-cx.ozonetel.com/resetpassword';
// const LOGIN_URL = 'https://api.preprod-cx.ozonetel.com/auth/login';
// const BASE_URL = 'https://api.preprod-cx.ozonetel.com/dashboardApi';
// const BASE_API_URL = 'https://api.preprod-cx.ozonetel.com/reportApi/endpoint';
// const LIVE_BASE_URL = 'https://api.preprod-cx.ozonetel.com/liveReportApi';
// const ADMIN_BASE_URL = 'https://api.preprod-cx.ozonetel.com/ca-admin-Api/CloudAgentAPI';
// const AGENT_LOGIN_URL = 'https://agent.preprod-cx.ozonetel.com/login'
// const CRM_REDIRECT_URL = "https://crm.auth.ozonetel.com/auth/zoho/integration";
// const IVR_FLOW_URL = 'https://ivr.preprod-cx.ozonetel.com/ivrdd';
// const APP_URL = 'http://kookoo_apps/DDParser/v1/index.php?tag_id=start&flow_id=';
// const IMAGE_UPLOAD_URL = 'https://api.pre-prod.ozonetel.com';

// Test-DB
const UPDATE_PASSWORD_URL = 'https://api.cx.dev.ozonetel.com/updatepassword';
const FORGOT_PASSWORD_URL = 'https://api.cx.dev.ozonetel.com/resetpassword';
const LOGIN_URL = 'https://api.cx.dev.ozonetel.com/auth/login';
const BASE_URL = 'https://api.cx.dev.ozonetel.com/dashboardApi';
const BASE_API_URL = 'https://api.cx.dev.ozonetel.com/reportApi/endpoint';
const LIVE_BASE_URL = 'https://api.cx.dev.ozonetel.com/liveReportApi';
const ADMIN_BASE_URL = 'https://api.cx.dev.ozonetel.com/ca-admin-Api/CloudAgentAPI';
const AGENT_LOGIN_URL = 'https://agent.api.cx.dev.ozonetel.com/login'
const CRM_REDIRECT_URL = "https://crm.auth.ozonetel.com/auth/zoho/integration";
const IVR_FLOW_URL = 'https://ivr.api.cx.dev.ozonetel.com/ivrdd';
const APP_URL = 'http://kookoo_apps/DDParser/v1/index.php?tag_id=start&flow_id=';
const IMAGE_UPLOAD_URL = 'https://api.cx.dev.ozonetel.com';

const APXOR_KEY = '7cc77eb4-548d-41d8-afc9-364d564627ee';
const LOCATION = 'IN';

export { LOGIN_PAGE_BACKGROUND_IMG_URL,DateFormatCalenderInHistoricReports, LOGIN_URL, BASE_URL, BASE_API_URL, LIVE_BASE_URL, ADMIN_BASE_URL, CRM_REDIRECT_URL, AGENT_LOGIN_URL, IVR_FLOW_URL, DateTimeFormat, DateFormat, SystemDateFormat, FORGOT_PASSWORD_URL, UPDATE_PASSWORD_URL, APP_URL, IMAGE_UPLOAD_URL,APXOR_KEY,LOCATION }

